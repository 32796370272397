import React, { useCallback, useEffect, useState } from "react";
import $ from "jquery";
import { CUSTOMER_LOGO_SMALL } from "../constants/images";
import { Collective } from "../types/Collective";
import { Link, useLocation } from "react-router-dom";
import { Member } from "../types/Member";

type Props = {
  loggedOut?: boolean;
  currentYear: number;
  currentApp: string;
  currentUsername: string;
  allYears: number[];
  allApps: Collective[];
  allMembers: Member[];
  onToggleHelp: () => any;
};
export default function Header({
  loggedOut,
  currentApp,
  currentYear,
  currentUsername,
  allYears,
  allApps,
  allMembers,
  onToggleHelp,
}: Props) {
  const location = useLocation();
  const currentPage = location.pathname.split("/")[1];
  const [dropdownToggled, toggleDropDown] = useState(false);
  const [menuToggled, toggleMenu] = useState(false);
  const activeMember = allMembers?.find(({ so_id }) => so_id === window.soId);

  useEffect(() => {
    return () => {
      toggleDropDown(false);
      toggleMenu(false);
    };
  }, [location.pathname]);

  const handleChangeYear = useCallback(
    (year) => {
      window.location.href = `/${year}/${currentApp}/${currentPage}`;
    },
    [currentApp, currentPage]
  );

  const handleChangeApp = useCallback(
    (appGid) => {
      window.location.href = `/${currentYear}/${appGid}/${currentPage}`;
    },
    [currentYear, currentPage]
  );

  const handleChangeMember = useCallback(
    (soId) => {
      window.location.href = `/o/login_scan/?so_id=${soId}`; // TODO: change URL
    },
    [currentYear, currentPage]
  );

  const handleToggleCustomHelp = () => {
    $(".c-alert-wrapper").toggleClass("c-alert-wrapper--hidden");
  };

  useEffect(() => {
    if (menuToggled) toggleDropDown(false);
  }, [menuToggled]);
  useEffect(() => {
    if (dropdownToggled) toggleMenu(false);
  }, [dropdownToggled]);

  // toggle mobile navigation mode
  useEffect(() => {
    document.body.classList.toggle("c-navigation-mobile--is-open", menuToggled && !dropdownToggled);
  }, [menuToggled, dropdownToggled]);

  if (loggedOut) {
    return (
      <nav className="c-navigation">
        <a className="c-navigation__logo" href="/">
          <div className="c-navigation__logo">
            <img src={CUSTOMER_LOGO_SMALL} alt="Logo Boerennatuur" width="32px" height="32px" />
          </div>
        </a>
        <div className="c-navigation__user c-navigation__user--ope">
          <a className="c-navigation__help" onClick={handleToggleCustomHelp}>
            <svg className="c-icon c-icon--size-m c-navigation__help__icon">
              <use xlinkHref="#icon-chat" />
            </svg>
            Help
          </a>
        </div>
      </nav>
    );
  }

  return (
    <nav className="c-navigation">
      <div className="c-navigation__selects">
        {allApps.length > 0 ? (
          <div className="c-form-select c-form-select--transparent c-navigation__select">
            <select
              name="gidselect"
              id="gidselect"
              value={currentApp}
              onChange={(event) => handleChangeApp(event.target.value)}
            >
              {allApps.map((app) => (
                <option key={app.gid} value={app.gid}>
                  {app.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        {allMembers.length > 0 ? (
          <div className="c-form-select c-form-select--transparent c-navigation__select">
            <select
              name="memberselect"
              id="memberselect"
              value={`${activeMember?.so_id}`}
              onChange={(event) => handleChangeMember(event.target.value)}
            >
              {allMembers.map((member) => (
                <option key={member.so_id} value={member.so_id}>
                  {member.app_name} - {member.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        <div className="c-form-select c-form-select--transparent c-navigation__select">
          <select
            name="yearselect"
            id="yearselect"
            value={`${currentYear}`}
            onChange={(event) => handleChangeYear(event.target.value)}
          >
            {allYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ul className="c-navigation__list">
        <li className="c-navigation__item">
          <Link to="/home/" className="c-navigation__link">
            Home
          </Link>
        </li>
        <li className="c-navigation__item">
          <Link to="/kaart/" className="c-navigation__link">
            Kaart
          </Link>
        </li>
        <li className="c-navigation__item">
          <Link to="/percelen/" className="c-navigation__link">
            Percelen
          </Link>
        </li>
        <li className="c-navigation__item">
          <Link to="/meldingen/" className="c-navigation__link">
            Meldingen
          </Link>
        </li>
        {window.kpisEnabled ? (
          <li className="c-navigation__item">
            <Link to="/kpis/" className="c-navigation__link">
              KPI's
            </Link>
          </li>
        ) : null}
        {window.kpiAuthorizationsEnabled ? (
          <li className="c-navigation__item">
            <Link to="/machtigingen/" className="c-navigation__link">
              Machtigingen
            </Link>
          </li>
        ) : null}
        <li className="c-navigation__item">
          <Link to="/documenten/" className="c-navigation__link">
            Documenten
          </Link>
        </li>
        <li className="c-navigation__item">
          <Link to="/contact/" className="c-navigation__link">
            Contact
          </Link>
        </li>
      </ul>
      <div className={`c-navigation__user ${dropdownToggled ? "c-navigation__user--open" : ""}`}>
        <a className="c-navigation__help" onClick={onToggleHelp}>
          <svg className="c-icon c-icon--size-m c-navigation__help__icon">
            <use xlinkHref="#icon-chat" />
          </svg>
          Help
        </a>
        <a className="c-navigation__user-name" onClick={() => toggleDropDown(!dropdownToggled)}>
          {currentUsername}
        </a>
        <ul className="c-account">
          <li className="c-account__item">
            <Link to="/account/" className="c-account__link">
              Account
            </Link>
          </li>
          <li className="c-account__item">
            <Link to="/gegevens/" className="c-account__link">
              Mijn gegevens
            </Link>
          </li>
          <li className="c-account__item">
            <a className="c-account__link" href="/logout/">
              Uitloggen
            </a>
          </li>
        </ul>
      </div>

      <div className="c-navigation-mobile">
        <a className="c-navigation-mobile__toggle" onClick={() => toggleMenu(!menuToggled)}>
          Menu
        </a>
        <ul className="c-navigation-mobile__list">
          <li className="c-navigation-mobile__item">
            <Link to="/home/" className="c-navigation-mobile__link">
              Home
            </Link>
          </li>
          <li className="c-navigation-mobile__item">
            <Link to="/kaart/" className="c-navigation-mobile__link">
              Kaart
            </Link>
          </li>
          <li className="c-navigation-mobile__item">
            <Link to="/percelen/" className="c-navigation-mobile__link">
              Percelen
            </Link>
          </li>
          <li className="c-navigation-mobile__item">
            <Link to="/meldingen/" className="c-navigation-mobile__link">
              Meldingen
            </Link>
          </li>
          {window.kpisEnabled ? (
            <li className="c-navigation-mobile__item">
              <Link to="/kpis/" className="c-navigation-mobile__link">
                KPI's
              </Link>
            </li>
          ) : null}
          {window.kpiAuthorizationsEnabled ? (
            <li className="c-navigation-mobile__item">
              <Link to="/machtigingen/" className="c-navigation-mobile__link">
                Machtigingen
              </Link>
            </li>
          ) : null}
          <li className="c-navigation-mobile__item">
            <Link to="/documenten/" className="c-navigation-mobile__link">
              Documenten
            </Link>
          </li>
          <li className="c-navigation-mobile__item">
            <Link to="/contact/" className="c-navigation-mobile__link">
              Contact
            </Link>
          </li>
          <li className="c-navigation-mobile__item">
            <Link to="/gegevens/" className="c-navigation-mobile__link">
              Mijn gegevens
            </Link>
          </li>
          <li className="c-navigation-mobile__item">
            <a className="c-navigation-mobile__link" href="/logout/">
              Uitloggen
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
